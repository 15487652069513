import { gql } from '@apollo/client';

export const USER_LOGIN = gql`
  query UserLogin($username: String!, $password: String!) {
    userLogin(userName: $username, password: $password)
  }
`; 

export const GET_STATE_LIST = gql`
  query GetStateList {
    states {
      stateId
      stateName
      countryId
    }
  }
`; 

export const GET_COUNTRY_LIST = gql`
  query GetCountryList {
    countries {
      countryId
      countryName
    }
  }
`; 