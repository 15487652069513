import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/App.css';
import Header from './components/Header.tsx';
import Navbar from './components/Navbar.tsx';
import Login from './pages/Login.tsx';
import User from './pages/User.tsx';
import bgImage from './assets/img/Yggdrasil.jpg';
import { ApolloProvider } from '@apollo/client';
import { client } from './services/graphql.ts';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('token'));

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    setIsLoggedIn(false);
    window.location.reload();
  };

  return (
    <Router>
      <div className="App" style={{ 
        '--bg-image': `url(${bgImage})` 
      } as React.CSSProperties}>
        <Header />
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/user" element={<User />} />
          <Route path="/" element={
            <main className="main-content">
              <div className="auth-buttons">
                {isLoggedIn ? (
                  <button className="btn-auth" onClick={handleLogout}>Logout</button>
                ) : (
                  <>
                    <button className="btn-auth" onClick={() => window.location.href = '/login'}>Login</button>
                    <button className="btn-auth" onClick={() => window.location.href = '/user'}>Create Account</button>
                  </>
                )}
              </div>
            </main>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
