import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/User.css';
import { useLazyQuery } from '@apollo/client';
import { GET_COUNTRY_LIST, GET_STATE_LIST } from '../graphql/users.ts';

interface UserFormData {
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  emailAddress: string;
  countryCode: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  isPrivate: boolean;
}

const User: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<UserFormData>({
    userName: '',
    firstName: '',
    lastName: '',
    password: '',
    emailAddress: '',
    countryCode: '+1',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    country: 'USA',
    zip: '',
    isPrivate: false
  });

  const countryCodes = ['+1', '+44', '+49', '+33', '+81'];
  const [getCountries, { data: countryData }] = useLazyQuery(GET_COUNTRY_LIST);
  const [getStates, { data: stateData }] = useLazyQuery(GET_STATE_LIST);

  // Load countries on component mount
  React.useEffect(() => {
    getCountries();
  }, [getCountries]);

  // Load states when country changes
  React.useEffect(() => {
    if (formData.country) {
      getStates();
    }
  }, [formData.country, getStates]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    setFormData(prev => ({ ...prev, [name]: newValue }));
    
    // Clear state selection when country changes
    if (name === 'country') {
      setFormData(prev => ({ ...prev, state: '', [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // TODO: Add submission logic
    console.log(formData);
  };

  return (
    <div className="user-container">
      <form className="user-form" onSubmit={handleSubmit}>
        <h2>User Information</h2>
        <p className="required-note">* Required fields</p>
        
        <div className="form-group">
          <label>Username *</label>
          <input
            type="text"
            name="userName"
            placeholder="Username"
            value={formData.userName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>First Name *</label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name *</label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>Password *</label>
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Email Address *</label>
          <input
            type="email"
            name="emailAddress"
            placeholder="Email Address"
            value={formData.emailAddress}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-row">
          <div className="form-group select-small">
            <select
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              required
            >
              {countryCodes.map(code => (
                <option key={code} value={code}>{code}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-row">
          <div className="form-group">
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group select-medium">
            <select
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            >
              <option value="">Select State</option>
              {stateData?.states?.map(state => (
                <option key={state.stateId} value={state.stateId}>{state.stateName}</option>
              )) || null}
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            >
              {countryData?.countries?.map(country => (
                <option key={country.countryId} value={country.countryId}>{country.countryName}</option>
              )) || null}
            </select>
          </div>
          <div className="form-group">
            <input
              type="text"
              name="zip"
              placeholder="ZIP Code"
              value={formData.zip}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form-group checkbox">
          <label>
            <input
              type="checkbox"
              name="isPrivate"
              checked={formData.isPrivate}
              onChange={handleChange}
            />
            Private Profile
          </label>
        </div>

        <div className="button-group">
          <button type="submit" className="btn-auth">Submit</button>
          <button 
            type="button" 
            className="btn-auth btn-cancel"
            onClick={() => navigate('/')}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default User;
