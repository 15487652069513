import React from 'react';
import yggdrasil from '../assets/img/Yggdrasil.jpg';
import '../styles/Header.css';
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  username: string;
  exp: number;
}

const Header: React.FC = () => {
  const token = sessionStorage.getItem('token');
  let username = '';
  
  if (token) {
    const decoded = jwtDecode<DecodedToken>(token);
    username = decoded.username;
  }

  return (
    <header className="header">
      <div className="header-container">
        <img src={yggdrasil} alt="Yggdrasil" />
      </div>
      <div className="header-text">
        <h1>Yggdrasil</h1>
        <p>a table top RPG management tool</p>
      </div>
      <div className="user-info">
        {username && <p>{username}</p>}
      </div>
    </header>
  );
};

export default Header; 