import React from 'react';
import '../styles/Navbar.css';

const Navbar: React.FC = () => {
  const token = sessionStorage.getItem('token');

  if (!token) return null;

  return (
    <nav className="navbar">
      <ul className="nav-list">
        <li className="nav-item">
          <a href="/">Home</a>
        </li>
        <li className="nav-item">
          <a href="/characters">Characters</a>
        </li>
        <li className="nav-item">
          <a href="/inventory">Inventory</a>
        </li>
        <li className="nav-item">
          <a href="/quests">Quests</a>
        </li>
        <li className="nav-item">
          <a href="/settings">Settings</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar; 